<template>
    <div>
        <b-form >
            <b-row>
                 <b-col
                    cols="12"
                    class="mb-2"
                >
                    <h5 class="mb-0">
                        Contenido Educativo del Módulo
                    </h5>
                    <small class="text-muted">
                        Actualice los detalles del contenido educativo
                    </small>
                    <hr>
                </b-col>
                
                <!-- Módulos -->
                <b-col 
                    cols="6"
                >  
                    <!-- rules="url" -->
                    <validation-provider
                            #default="{ errors }"
                            name="enlace del video"
                            rules="required"
                    >  
                        <b-form-group
                            label="Enlace del video"
                            label-for="url"
                        >   
                            <b-input-group>
                                <b-input-group-prepend>
                                    <b-button variant="outline-primary">
                                    <feather-icon icon="VideoIcon" />
                                    </b-button>
                                </b-input-group-prepend>
                                <!-- placeholder="Ingrese código de Vimeo" -->
                                <b-form-input
                                    id="url"
                                    type="text"
                                    v-model="url_content"
                                    placeholder="Ingrese el enlace del video"
                                />
                            </b-input-group>     
                            <small class="text-danger">{{ errors[0] }}</small>                                        
                        </b-form-group>
                    </validation-provider>
                </b-col>
            </b-row>
        </b-form>  
        <br>
        <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="updateInformation"
        >
            Guardar cambios
        </b-button> 
    </div>
</template>

<script>
    require('@core/assets/fonts/feather/iconfont.css')
    import { useToast } from 'vue-toastification/composition';
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
    import { VBTooltip, BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BInputGroupPrepend, BInputGroup, BForm, BFormTextarea, BFormCheckbox,
			BCardText, BEmbed} from 'bootstrap-vue';
    import vSelect from 'vue-select';
    import { url} from '@validations'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import Ripple from 'vue-ripple-directive'
    import { ref } from '@vue/composition-api';
    import axios from 'axios';

export default {
        components: {
            BButton,
            BMedia,
            BAvatar,
            BRow,
            BCol,
            BFormGroup,
            BFormInput,
            BFormTextarea,
            BForm,
            BEmbed,
            BFormCheckbox,
			BCardText,
            BCardActions,
            BInputGroupPrepend,
            BInputGroup,
            vSelect,
            
            ValidationProvider,
            ValidationObserver,
        },
        directives: {
            'b-tooltip': VBTooltip,
		    Ripple,
	    },
        props: {
           userData: {
                type: Object,
                required: true,
            },
        },        
        data() {
            return {
                url_content: null,
                courseId: 0,
                moduleId: 0,
                url,
            }
        },
        mounted (){
            this.url_content = this.userData.url_content;
            this.courseId = this.userData.courseId;
            this.moduleId = this.userData.id;
        },
        methods: {
            updateInformation(){
                let error = 0;
                let errorE1 = 0;                
                if(this.url_content === ''){ error = 1; }
                
                if(error){
                    errorE1 = 1;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Debe completar la información del campo.',
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                        }
                    });
                }
                
                if(!errorE1){
                    let moduleData = {
                        id : this.moduleId,
                        url_content: this.url_content,
                    }                    
                    axios.put(`${process.env.VUE_APP_HOST}/api/dashboard/course/${this.courseId}/module/content/update`, moduleData)
                    .then(response => {                                           
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: response.data.message,
                                icon: 'CheckIcon',
                                variant: 'success',
                            },
                        });                          
                    })
                    .catch( (err) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Error al actualizar el contenido introductorio del módulo.',
                                icon: 'AlertTriangleIcon',
                                variant: 'danger'
                            }
                        });
                    }); 
                }
            }
        },
    }
</script>


<style lang="scss">
    #card-module {
        background: rgb(22, 29, 49);
    }
    .url_vimeo{
        margin: 0 auto;
    }
</style>

