import { ref, watch, computed } from '@vue/composition-api';
import store from '@/store';
import router from '@/router';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default function useMaterialsList() {
    // Use toast
    const toast = useToast();
    
    const refUserListTable = ref(null);
    
    // Table Handlers
    const tableColumns = [
        { key: 'index', label: '#' },
        { key: 'name', sortable: true, label: 'Nombre' },
        { key: 'file', sortable: true, label: 'Archivo' },
        { key: 'type', sortable: true, label: 'Tipo' },
        { key: 'actions', label: 'Opciones' }
    ];
    
    const perPage = ref(10);
    const totalUsers = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref('');
    const sortBy = ref('id');
    const isSortDirDesc = ref(false);
    
    const dataMeta = computed(() => {
        const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0;
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalUsers.value,
        }
    });
    
    const refetchData = () => {
        refUserListTable.value.refresh()
    };
    
    watch([currentPage, perPage, searchQuery], () => {
        refetchData()
    });
    
    const fetchMaterials = (ctx, callback) => {
        store
        .dispatch('app-user/fetchMaterials', {    
            id: router.currentRoute.params.moduleId,        
            q: searchQuery.value,
            perPage: perPage.value,
            page: currentPage.value,
            sortBy: sortBy.value,
            sortDesc: isSortDirDesc.value,
        })
        .then(response => {
            const { materials, total } = response.data;
            callback(materials);
            totalUsers.value = total;
        })
        .catch(() => {
            toast({
                component: ToastificationContent,
                props: {
                    title: 'Error al obtener la lista de los materiales.',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                },
            });
        })
    };
    
    return {
        fetchMaterials,
        tableColumns,
        perPage,
        currentPage,
        totalUsers,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refUserListTable,
        
        refetchData,
    }
}
