<template>
    <b-sidebar
        id="add-new-user-sidebar"
        :visible="isAddNewUserSidebarActive"
        bg-variant="white"
        sidebar-class="sidebar-lg"
        shadow
        backdrop
        no-header
        right
        @hidden="resetForm"
        @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
    >
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">
                    <strong>AGREGAR NUEVO ARCHIVO</strong>
                </h5>

                <feather-icon
                    class="ml-1 cursor-pointer"
                    icon="XIcon"
                    size="16"
                    @click="hide"
                />

            </div>

            <!-- BODY -->
            <validation-observer
                #default="{ handleSubmit }"
                ref="refFormObserver"
            >
                <!-- Form -->
                <b-form
                    class="p-2"
                    @submit.prevent="handleSubmit(onSubmit)"
                    @reset.prevent="resetForm"
                >

                    <!-- Material -->
                    <validation-provider
                        #default="validationContext"
                        name="Nombre"
                        rules="required"
                    >
                        <b-form-group
                            label="Nombre del archivo"
                            label-for="name"
                        >
                            <b-form-input
                                id="name"
                                v-model="userData.name"
                                :state="getValidationState(validationContext)"
                                trim
                            />

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                    
                    <!-- Logo + File -->
                    <validation-provider
                        #default="{ errors }"
                        name="archivo"
                        rules="required"
                    >
                        <b-form-group
                            label="Archivo"
                            label-for="file"
                        >
                            <b-form-file
                                id="file"
                                v-model="userData.file"                           
                                placeholder="Elija el archivo o suéltelo aquí ..."               
                                accept=".pdf, .xls, .xlsx, .docx, .doc, .ppt, .pptx"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                    </validation-provider>

                    <!-- Form Actions -->
                    <div class="d-flex mt-2">
                        <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            class="mr-2"
                            type="submit"
                        >
                            Agregar
                        </b-button>
                        <b-button
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            type="button"
                            variant="outline-secondary"
                            @click="hide"
                        >
                            Cancelar
                        </b-button>
                    </div>

                </b-form>
            </validation-observer>
        </template>
    </b-sidebar>
</template>

<script>
    import { useToast } from 'vue-toastification/composition';
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import {
        BSidebar, BForm, BFormGroup, BFormInput, BFormFile, BFormInvalidFeedback, BButton,
    } from 'bootstrap-vue'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { ref } from '@vue/composition-api'
    import { required, alphaNum, email, digits } from '@validations'
    import formValidation from '@core/comp-functions/forms/form-validation'
    import Ripple from 'vue-ripple-directive'
    import vSelect from 'vue-select'
    import store from '@/store'
    import router from '@/router'

    export default {
        components: {
            BSidebar,
            BForm,
            BFormGroup,
            BFormInput,
            BFormInvalidFeedback,
            BButton,
            BFormFile,
            vSelect,

            // Form Validation
            ValidationProvider,
            ValidationObserver,
        },
        directives: {
            Ripple,
        },
        model: {
            prop: 'isAddNewUserSidebarActive',
            event: 'update:is-add-new-user-sidebar-active',
        },
        props: {
            isAddNewUserSidebarActive: {
                type: Boolean,
                required: true,
            },
        },
        data() {
            return {
                required,
                alphaNum,
                email,
                digits,
            }
        },
        setup(props, { emit }) {

            
            // Use toast
            const toast = useToast();

            const blankUserData = {
                name: '',
                file: null,
            }

            const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
            const resetuserData = () => {
                userData.value = JSON.parse(JSON.stringify(blankUserData))
            }
            
            const onSubmit = () => {       
                if(userData.value.file){

                    let materialData = {
                        file: userData.value.file,
                        name: userData.value.name,
                    }
                                        
                    store.dispatch('app-user/addMaterial', {id: router.currentRoute.params.moduleId, materialData })
                        .then(() => {
                            emit('refetch-data');

                            toast({
                                component: ToastificationContent,
                                props: {
                                    title: 'Se ha creado correctamente el Material.',
                                    icon: 'CheckIcon',
                                    variant: 'success',
                                },
                            });
                            
                            emit('update:is-add-new-user-sidebar-active', false);
                        })
                        .catch( (error) => {
                            console.log('error', error);
                            toast({
                                component: ToastificationContent,
                                props: {
                                    title: 'Error al crear al Material.',
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger',
                                },
                            });
                        });
                        
                } else {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Debe cargar un archivo.',
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                        }
                    });
                }
                
            }

            const {
                refFormObserver,
                getValidationState,
                resetForm,
            } = formValidation(resetuserData)

            return {
                userData,
                onSubmit,

                refFormObserver,
                getValidationState,
                resetForm,
            }
        },
    }
</script>

<style lang="scss">
    
    #add-new-user-sidebar {
        .vs__dropdown-menu {
            max-height: 200px !important;
        }
    }
</style>
