<template>
    <div>

        <b-form >
            <b-row>
                 <b-col
                    cols="12"
                    class="mb-2"
                >
                    <h5 class="mb-0">
                        Exámenes del Módulo
                    </h5>
                    <small class="text-muted">
                        Actualice los detalles del examen
                    </small>
                    <hr>
                </b-col>
                
                <!-- button modal module-->
                <b-col
                    cols="12"
                    class="mb-2"
                >                                     
                    <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                         :to="{ name: 'exam-create', params: { id: userData.id }}"
                    >
                        <feather-icon
                            class="cursor-pointer"
                            icon="PlusIcon"
                        />
                        Crear Examen
                    </b-button>
                
                </b-col>  
                
                <!-- Módulos -->
                <b-col 
                    cols="12"
                >                      
                     <b-table
                        responsive="sm"
                        :fields="fields"
                        :items="examArr"
                        show-empty
                        empty-text="No matching records found"
                    >
                        <template #cell(time)="data">
                            <span class="align-middle ml-50">{{`${data.item.time} min`}}</span>
                        </template>
                        <!-- Column: Actions -->
                        <template #cell(actions)="data">
                            <b-dropdown
                                variant="link"
                                no-caret
                                :right="$store.state.appConfig.isRTL"
                            >

                                <template #button-content>
                                    <feather-icon
                                        icon="MoreVerticalIcon"
                                        size="16"
                                        class="align-middle text-body"
                                    />
                                </template>

                                <b-dropdown-item :to="{ name: 'exam-edit', params: { id: data.item.id } }">
                                    <feather-icon icon="EditIcon" />
                                    <span class="align-middle ml-50">Editar</span>
                                </b-dropdown-item>

                                <b-dropdown-item @click="deleteExam(data.index, data.item.id)">
                                    <feather-icon icon="TrashIcon" />
                                    <span class="align-middle ml-50">Eliminar</span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </template>
                    </b-table>
                    
                </b-col>	
            </b-row>            
        </b-form>    
    </div>
</template>

<script>
    require('@core/assets/fonts/feather/iconfont.css')
    import { useToast } from 'vue-toastification/composition';
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
    import { VBTooltip, BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BFormTextarea, BFormCheckbox,
			BCardText, BDropdown, BDropdownItem, BTable,} from 'bootstrap-vue';
    import { avatarText } from '@core/utils/filter';
    import vSelect from 'vue-select';
    import Ripple from 'vue-ripple-directive'
    import { ref } from '@vue/composition-api';
    import store from '@/store';
    import axios from 'axios';

export default {
        components: {
            BDropdown, 
            BDropdownItem,
            BTable,
            BButton,
            BMedia,
            BAvatar,
            BRow,
            BCol,
            BFormGroup,
            BFormInput,
            BFormTextarea,
            BForm,
            BFormCheckbox,
			BCardText,
            BCardActions,
            vSelect,
        },
        directives: {
            'b-tooltip': VBTooltip,
		    Ripple,
	    },
        props: {
            userData: {
                type: Object,
                required: true,
            },
        },
        data() {            
            return {
                fields: [
                { key: 'title', label: 'Examen' },  
                { key: 'time', label: 'Duración' },
                { key: 'n_questions', label: 'Cantidad de preguntas' },
                 { key: 'actions', label: 'Acción' },
                ],
                exams: [],
                examArr: [],
                moduleId: null,
            }
        },    
         mounted (){            
            this.moduleId = this.userData.id           
            axios.get(`${process.env.VUE_APP_HOST}/api/dashboard/course/module/${this.moduleId}/exam/list`)
            .then(response => {			
                this.exams = response.data;
            });
            
            setTimeout(() => {
                this.examList(this.exams);
            }, 500);
            
        },    
        methods:{
            examList (exams){
                this.examArr = [];		  
                this.questionExamId = [];
                exams.map(item => {
                    this.examArr.push({
                        id: item.id,
                        title: item.title,
                        time: item.time,
                        moduleId: item.moduleId,
                        n_questions: item.n_questions,
                    });
                });    
            },
            deleteExam (index, id){    
                axios.get(`${process.env.VUE_APP_HOST}/api/dashboard/course/module/exam/${id}/delete`)
                    .then(response => {                                           
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: response.data.message,
                                icon: 'CheckIcon',
                                variant: 'success',
                            },
                        });            
                        this.examArr.splice(index, 1);        
                    })
                    .catch( (err) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Error al eliminar el Examen.',
                                icon: 'AlertTriangleIcon',
                                variant: 'danger'
                            }
                        });
                    }); 
            }
        },
    }
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">
    #card-module {
        background: rgb(22, 29, 49);
    }
</style>