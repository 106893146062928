<template>
	<component :is="userData === undefined ? 'div' : 'b-card'">

		<!-- Alert: No item found -->
		<b-alert
			variant="danger"
			:show="userData === undefined"
		>
			<h4 class="alert-heading">
				Error al obtener los datos del módulo
			</h4>
			<div class="alert-body">
				No se encontró ningún módulo con este id. Verificar en la
				<b-link
					class="alert-link"
					:to="{ name: 'courses-module'}"
				>
					Lista de módulos
				</b-link>
				para buscar otro módulo.
			</div>
		</b-alert>

		<b-tabs
			v-if="userData"
			pills
		>
		
		<!-- Tab: Contenido Educativo -->
			<b-tab>
				<template #title>
					<feather-icon
						icon="InfoIcon"
						size="16"
						class="mr-0 mr-sm-50"
					/>
					<span class="d-none d-sm-inline">Contenido Educativo</span>
				</template>
				<content-edit-tab-information 
					class="mt-2 pt-75"           
					:user-data="userData"
					/>
			</b-tab>
		
		<!-- Tab: Material -->
			<b-tab>
				<template #title>
					<feather-icon
						icon="InfoIcon"
						size="16"
						class="mr-0 mr-sm-50"
					/>
					<span class="d-none d-sm-inline">Material</span>
				</template>
				<material-list 
					class="mt-2 pt-75" 
					:user-data="userData"
					/>
			</b-tab>
		
		<!-- Tab: Exámenes -->
			<b-tab>
				<template #title>
					<feather-icon
						icon="InfoIcon"
						size="16"
						class="mr-0 mr-sm-50"
					/>
					<span class="d-none d-sm-inline">Exámenes</span>
				</template>
				<exam-list 
					class="mt-2 pt-75" 
					:user-data="userData"
					/>
			</b-tab>	  
			
		</b-tabs>
	</component>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink } from 'bootstrap-vue';
import { ref, onUnmounted } from '@vue/composition-api';
import router from '@/router';
import store from '@/store';
import moduleStoreModule from '../moduleStoreModule';
import ContentEditTabInformation from './contents-tab//ContentEditTabInformation.vue';
import MaterialList from './materials-tab/MaterialList.vue';
import ExamList from './exams-tab/ExamsList.vue';

export default {
	components: {
		BTab,
		BTabs,
		BCard,
		BAlert,
		BLink,
	
		ContentEditTabInformation,
		MaterialList,
		ExamList,
	},
	setup() {		

		const USER_APP_STORE_MODULE_NAME = 'app-user'

		// Register module
		if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, moduleStoreModule)

		// UnRegister on leave
		onUnmounted(() => {
			if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
		})
		
		const userData = ref(null)			
		store.dispatch('app-user/fetchModule', { id: router.currentRoute.params.id, moduleId: router.currentRoute.params.moduleId })
		.then(response => {
				if (response.data === 'error') {
						userData.value = undefined
				} else {
						userData.value = {
								...response.data
						}
				}
		})
		.catch(error => {
				if (error.response.status === 404) {
						userData.value = undefined
				}
		});
		
		return {
			userData,
		}		
	},
}
</script>