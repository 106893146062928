import axios from '@axios';

export default {
	namespaced: true,
	state: {},
	getters: {},
	mutations: {},
	actions: {        		
		fetchModule (ctx, { id, moduleId }) {
		    return new Promise((resolve, reject) => {
				axios
				.get(`${process.env.VUE_APP_HOST}/api/dashboard/course/${id}/module/${moduleId}/edit`)
				.then(response => resolve(response))
				.catch(error => reject(error));
			})
		},
		
		fetchMaterials (ctx, queryParams){			
			return new Promise((resolve, reject) => {
				axios
				.get(`${process.env.VUE_APP_HOST}/api/dashboard/course/module/${queryParams.id}/material/list`, { params: queryParams })
				.then(response => resolve(response))
				.catch(error => reject(error));
			})
		},
		
		addMaterial (ctx, { id, materialData }) {
						
			let formData = new FormData();
			
			formData.append("file", materialData.file);		
			formData.append("name", materialData.name);
				
			return new Promise((resolve, reject) => { 
				axios
				.post(`${process.env.VUE_APP_HOST}/api/dashboard/course/module/${id}/material/create`, formData, {
					headers: { 
						'Content-Type': 'multipart/form-data'
					}
				})
				.then( response => resolve(response))
				.catch(error => reject(error));
			});
		},
		
		deleteMaterial (ctx, { id }){
			return new Promise((resolve, reject) => {
				axios
				.get(`${process.env.VUE_APP_HOST}/api/dashboard/course/module/material/${id}/delete`)
				.then(response => resolve(response))
				.catch(error => reject(error));
			});
		}, 
		
		
	},
}
